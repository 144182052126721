import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import {
  Autocomplete,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Grid,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { setTemplate } from '../../../helpers/backend_helper';
import { toast } from 'react-toastify';

const style = {
  maxWidth: '500px',
  width: '90%',
  borderRadius: '20px',
  bgcolor: 'background.paper',
  border: '2px solid transparent',
  display: 'flex',
  flexDirection: 'column',
};

const PaymentLinkModal = ({ handleNewTemplate, isOpen }) => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const formattedTomorrow = tomorrow.toISOString().split('T')[0];
  const twelveMonthsFromNow = new Date(today);
  twelveMonthsFromNow.setFullYear(twelveMonthsFromNow.getFullYear() + 1);
  const formattedTwelveMonthsFromNow = twelveMonthsFromNow
    .toISOString()
    .split('T')[0];

  const [templateData, setTemplateData] = useState({
    firstName: '',
    firstNameError: null,
    lastName: '',
    lastNameError: null,
    email: '',
    emailError: null,
    name: '',
    nameError: null,
    amount: '',
    amountError: null,
    instantDebitAmount: '',
    frequency: '',
    frequencyError: null,
    debitDate: '',
    debitDateError: null,
    paymentCount: '',
    paymentCountError: null,
  });

  const [maxPaymentCount, setMaxPaymentCount] = useState(12);

  useEffect(() => {
    if (templateData.frequency === 'Weekly') {
      setMaxPaymentCount(52);
    } else if (templateData.frequency === 'Monthly') {
      setMaxPaymentCount(12);
    }
  }, [templateData.frequency]);

  const handleChange = (name, value) => {
    let newState = { ...templateData };
    if (value) {
      newState[name + 'Error'] = null;
    }
    if (name === 'paymentCount') {
      if (value <= maxPaymentCount) {
        newState[name] = value;
      }
    } else {
      newState[name] = value;
    }
    setTemplateData(newState);
  };

  const handleCreateTemplate = async () => {
    let newState = { ...templateData };
    if (newState.firstName === '') {
      newState.firstNameError = 'First Name is required.';
    }
    if (newState.lastName === '') {
      newState.lastNameError = 'Last Name is required.';
    }
    if (newState.email === '') {
      newState.lastNameError = 'Email Address is required.';
    }
    if (newState.name === '') {
      newState.nameError = 'Reason is required.';
    }
    if (newState.amount === '') {
      newState.amountError = 'Amount is required.';
    }
    if (newState.frequency === '') {
      newState.frequencyError = 'Frequency is required.';
    }
    if (newState.paymentCount === '') {
      newState.paymentCountError = 'No Of Payments is required.';
    }
    if (newState.debitDate === '') {
      newState.debitDateError = 'Debit Date is required.';
    }
    setTemplateData(newState);

    if (
      newState.firstName &&
      newState.lastName &&
      newState.email &&
      newState.name &&
      newState.amount &&
      newState.frequency &&
      newState.debitDate &&
      newState.paymentCount
    ) {
      await setTemplate({
        firstName: newState.firstName,
        lastName: newState.lastName,
        email: newState.email,
        name: newState.name,
        reasonForPayment: newState.name,
        amount: parseInt(newState.amount),
        paymentCount: parseInt(newState.paymentCount),
        instantDebitAmount: parseInt(newState.instantDebitAmount),
        frequency: newState.frequency,
        debitDate: newState.debitDate,
      })
        .then((res) => {
          setTemplateData({
            firstName: '',
            firstNameError: null,
            lastName: '',
            lastNameError: null,
            email: '',
            emailError: null,
            name: '',
            nameError: null,
            amount: '',
            amountError: null,
            instantDebitAmount: '',
            frequency: '',
            frequencyError: null,
            debitDate: '',
            debitDateError: null,
            paymentCount: '',
            paymentCountError: null,
          });
          toast.success('Mandate created successfully!');
          handleNewTemplate();
        })
        .catch((err) => {
          toast.error('Something went wrong!');
        });
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleNewTemplate}>
      <DialogTitle>
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Typography variant="h6" fontWeight={600}>
            Create Payment Link
          </Typography>
          <IconButton onClick={handleNewTemplate}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>

      <Divider />

      <DialogContent>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" fontWeight={500} mt={1}>
                First Name
              </Typography>
              <TextField
                type="text"
                fullWidth
                value={templateData.firstName}
                placeholder="Enter First Name"
                sx={{ mt: 1 }}
                onChange={(e) => handleChange('firstName', e.target.value)}
                helperText={templateData.firstNameError}
                error={Boolean(templateData.firstNameError)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" fontWeight={500} mt={1}>
                Last Name
              </Typography>
              <TextField
                type="text"
                fullWidth
                value={templateData.lastName}
                placeholder="Enter Last Name"
                sx={{ mt: 1 }}
                onChange={(e) => handleChange('lastName', e.target.value)}
                helperText={templateData.lastNameError}
                error={Boolean(templateData.lastNameError)}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" fontWeight={500} mt={1}>
                Email Address
              </Typography>
              <TextField
                type="text"
                fullWidth
                value={templateData.email}
                placeholder="Enter Email Address"
                sx={{ mt: 1 }}
                onChange={(e) => handleChange('email', e.target.value)}
                helperText={templateData.emailError}
                error={Boolean(templateData.emailError)}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" fontWeight={500} mt={1}>
                Reason for payment
              </Typography>
              <TextField
                type="text"
                fullWidth
                value={templateData.name}
                placeholder="Reason for payment"
                sx={{ mt: 1 }}
                onChange={(e) => handleChange('name', e.target.value)}
                helperText={templateData.nameError}
                error={Boolean(templateData.nameError)}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" fontWeight={500} mt={1}>
                Amount
              </Typography>
              <TextField
                type="number"
                fullWidth
                value={templateData.amount}
                placeholder="Enter amount"
                sx={{ mt: 1 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography sx={{ color: '#A3A3A3' }}>₦</Typography>
                      <Typography sx={{ color: '#F0F0F0', ml: 1 }}>
                        |
                      </Typography>
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => handleChange('amount', e.target.value)}
                helperText={templateData.amountError}
                error={Boolean(templateData.amountError)}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                fontWeight={500}
                mt={1}
                sx={{ color: '#A3A3A3' }}
              >
                Amount to debit instantly (Optional)
              </Typography>
              <TextField
                type="number"
                fullWidth
                value={templateData.instantDebitAmount}
                disabled={true}
                placeholder="Enter amount"
                sx={{
                  mt: 1,
                  '& .MuiInputBase-input': {
                    // Targeting the input directly
                    color: '#A3A3A3', // Setting the text color
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography sx={{ color: '#A3A3A3' }}>₦</Typography>
                      <Typography sx={{ color: '#F0F0F0', ml: 1 }}>
                        |
                      </Typography>
                    </InputAdornment>
                  ),
                }}
                onChange={(e) =>
                  handleChange('instantDebitAmount', e.target.value)
                }
                helperText={templateData.amountError}
                error={Boolean(templateData.amountError)}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" fontWeight={500} mt={1}>
                Frequency
              </Typography>
              <Autocomplete
                id="frequency"
                fullWidth
                options={['Weekly', 'Monthly']}
                value={templateData.frequency}
                onChange={(e, newValue) => handleChange('frequency', newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select frequency"
                    sx={{ mt: 1 }}
                    helperText={templateData.frequencyError}
                    error={Boolean(templateData.frequencyError)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" fontWeight={500} mt={1}>
                Direct debit dates
              </Typography>
              <TextField
                type="date"
                fullWidth
                value={templateData.debitDate}
                placeholder="DD/MM/YYYY"
                sx={{ mt: 1 }}
                onChange={(e) => handleChange('debitDate', e.target.value)}
                helperText={templateData.debitDateError}
                error={Boolean(templateData.debitDateError)}
                InputProps={{
                  inputProps: {
                    min: formattedTomorrow, // Set min to today's date
                    max: formattedTwelveMonthsFromNow, // Set max to end of the month
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                label=""
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" fontWeight={500} mt={1}>
                No of payments
              </Typography>
              <TextField
                type="number"
                fullWidth
                value={templateData.paymentCount}
                placeholder="No of times payments"
                sx={{ mt: 1 }}
                onChange={(e) => handleChange('paymentCount', e.target.value)}
                helperText={templateData.paymentCountError}
                error={Boolean(templateData.paymentCountError)}
                InputProps={{
                  inputProps: {
                    min: 1,
                    max: maxPaymentCount,
                    step: 1,
                  },
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          sx={{ color: '#000', borderColor: '#DEDEDE' }}
          onClick={() => {
            handleNewTemplate();
            setTemplateData({
              name: '',
              nameError: null,
              amount: '',
              amountError: null,
              instantDebitAmount: '',
              frequency: '',
              frequencyError: null,
              debitDate: '',
              debitDateError: null,
              paymentCount: '',
              paymentCountError: null,
            });
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={{ color: '#fff' }}
          onClick={handleCreateTemplate}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PaymentLinkModal;